<template>
  <div>
    <LayoutRequestContent>
      <template #append>
        <div class="flex flex-col space-y-2">
          <RequestSidebarAssetSpecs />
        </div>
      </template>
    </LayoutRequestContent>
  </div>
</template>

<script>
import LayoutRequestContent from '@/components/Layout/LayoutRequestContent.vue';
import RequestSidebarAssetSpecs from '@/components/Request/RequestSidebar/RequestSidebarAssetSpecs.vue';

export default {
  name: 'RequestCreateCalculation',
  components: {
    LayoutRequestContent,
    RequestSidebarAssetSpecs,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
